import { lighten, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { createContext, forwardRef, TouchEventHandler, useCallback, useContext } from "react";
import { useTeamState } from "../../hooks/atoms/useTeam";
import { EDITION_META } from "../../reclaim-api/team/Team.consts";
import { ReclaimEditionStr } from "../../reclaim-api/team/Team.types";
import { EditionBadgeBase, EditionBadgeBaseProps } from "./EditionBadgeBase";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.caption,
    backgroundColor: lighten(theme.colors.grey, 0.6),
    borderRadius: 4,
    color: theme.palette.text.primary,
    display: "inline-block",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "16px",
    padding: theme.spacing(0, 0.5),
    textTransform: "uppercase",
    pointerEvents: "auto",
  },
}));

export type EditionBadgeProps = Omit<EditionBadgeBaseProps, "paid" | "classes"> & {
  edition: ReclaimEditionStr;
  EditionBadgeBaseClasses?: EditionBadgeBaseProps["classes"];
  useShortLabel?: boolean;
};

export type EditionBadgeContextData = { tooltipOpen: boolean; setTooltipOpen: (open: boolean) => void };
export const EditionBadgeContext = createContext<EditionBadgeContextData>({
  tooltipOpen: false,
  setTooltipOpen: () => void 0,
});

export const EditionBadge = forwardRef<HTMLDivElement, EditionBadgeProps>(
  ({ edition, className, EditionBadgeBaseClasses, useShortLabel, ...rest }, ref) => {
    const classes = useStyles();
    const { tooltipOpen, setTooltipOpen } = useContext(EditionBadgeContext);
    const { shortLabel, label, isTrial } = EDITION_META[edition];
    const { team } = useTeamState();

    const handleTouchEndCapture = useCallback<TouchEventHandler<HTMLDivElement>>(
      (e) => {
        if (!tooltipOpen) {
          // preventDefault on touchEnd blocks click event
          e.preventDefault();
          e.stopPropagation();
          setTooltipOpen(true);
        } else setTooltipOpen(false);
      },
      [setTooltipOpen, tooltipOpen]
    );

    return (
      <EditionBadgeBase
        onTouchEndCapture={handleTouchEndCapture}
        className={clsx(classes.root, className)}
        paid={edition !== "LITE" && edition !== "ASSISTANT"}
        classes={EditionBadgeBaseClasses}
        ref={ref}
        {...rest}
      >
        {useShortLabel ? shortLabel || label : label}
        {isTrial || team?.pricingSummary.subscriptionStatus === "TRIALING" ? " trial" : ""}
      </EditionBadgeBase>
    );
  }
);
